import React, { useState, useEffect } from "react";
import {
  StyledContactForm,
  TextContainer,
  Title,
  Body
} from './styles';

function ObfuscatedEmail({ email }) {
  const [encodedEmail, setEncodedEmail] = useState('');

  useEffect(() => {
    const encoded = btoa(email);
    setEncodedEmail(encoded);
  }, [email]);

  const handleClick = () => {
    window.location.href = `mailto:${atob(encodedEmail)}`;
  };

  return (
    <a href="#" style={{ fontSize: '19px', fontFamily: 'Inter, sans-serif', color: 'rgb(0, 107, 182)' }} onClick={handleClick}>En cliquant ici.</a>
  );
}

const ContactForm = ({title, exerpt}) => {
  return (
    <StyledContactForm>
      <TextContainer>
        {title && <Title>Contactez-nous</Title>}
        {exerpt && <Body>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas eu diam vel morbi curabitur fermentum, euismod. Cursus at aenean facilisis lacus, urna at. In a euismod fames massa quis</Body>}
      </TextContainer>
      <ObfuscatedEmail email="sante@sve.umontreal.ca" />
    </StyledContactForm>
  )
}

export default ContactForm;