import styled from 'styled-components';

export const StyledContactForm = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto 55px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin: 0 auto 85px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin: 0 auto 85px;
  }
`;

export const TextContainer = styled.div`
  margin-bottom: 60px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 90px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-bottom: 80px;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 150%;
  font-weight: 900;
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 40px;
  }
`;

export const Body = styled.p`
  font-size: 16px;
  line-height: 150%;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-size: 19px;
  }
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 100%;
  gap: 30px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    gap: 60px 0;
  }
`;

export const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 20px;
  }
`;

export const Button = styled.button`
  margin-left: auto;
  padding: 10px;
  width: fit-content;
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.blue};

  &.success {
    background-color: ${(props) => props.theme.colors.green};
  }

  &.error {
    background-color: ${(props) => props.theme.colors.red};
  }
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 125%;

  &.error {
    color: ${(props) => props.theme.colors.red};
  }

  &.error .ErrorText {
    display: block;
  }

  &.error .Input {
    border-color: ${(props) => props.theme.colors.red};
  }
`;

export const Input = styled.input`
  margin-top: 5px;
  width: 100%;
  height: 35px;
  padding: 5px;
  font-size: 14px;
  line-height: 125%;
  color: ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue};
  outline: none;
  border-radius: 0;

  &:focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  margin-top: 5px;
  width: 100%;
  height: 100px;
  padding: 5px;
  font-size: 14px;
  line-height: 125%;
  color: ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue};
  outline: none;
  border-radius: 0;

  &:focus {
    outline: none;
  }
`;

export const ErrorText = styled.span`
  display: none;
  margin-top: 5px;
  font-size: 14px;
  line-height: 125%;
  color: ${(props) => props.theme.colors.red};
`;
