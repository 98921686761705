import React, { useRef, useEffect } from 'react';
import { gsap, Power1 } from 'gsap';
import PropTypes from 'prop-types';
import {
  GenericHeadWrapper,
  GenericTitleWrapper,
  GenericHeadBodyWrapper,
  GenericTitle,
  GenericHeadBody,
  MediaAssetWrapper,
} from './styles';
import MediaAsset from 'components/UI/MediaAsset'

const GenericHead = ({ title, headBody, image }) => {
  const headRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    const title = headRef.current.querySelector('h1');
    const bodyText = headRef.current.querySelector('p');
    const mediaWrapper = headRef.current.querySelector('#media-wrapper');

    const tl = gsap
      .timeline()
      .fromTo(
        title,
        { autoAlpha: 0, y: '100%' },
        { autoAlpha: 1, y: 0, duration: 0.8, ease: Power1.easeOut },
        0
      )
      .fromTo(
        bodyText,
        { autoAlpha: 0, y: '40px' },
        { autoAlpha: 1, y: 0, duration: 0.8, ease: Power1.easeOut },
        '-=.3'
      )
      .fromTo(
        mediaWrapper,
        { opacity: 0, y: '40px' },
        { opacity: 1, y: 0, duration: 0.8, ease: Power1.easeOut },
        '-=.2'
      );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <GenericHeadWrapper ref={headRef}>
      {title && (
        <GenericTitleWrapper ref={titleRef}>
          <GenericTitle theme="h1">{title}</GenericTitle>
        </GenericTitleWrapper>
      )}
      {headBody && (
        <GenericHeadBodyWrapper>
          <GenericHeadBody theme="body-l">{headBody}</GenericHeadBody>
        </GenericHeadBodyWrapper>
      )}
      {image && (
        <MediaAssetWrapper id="media-wrapper">
          <MediaAsset image={image} />
        </MediaAssetWrapper>
      )}
    </GenericHeadWrapper>
  );
};

GenericHead.propTypes = {
  title: PropTypes.string,
  headBody: PropTypes.string,
  image: PropTypes.object,
};

export default GenericHead;
