import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const GenericHeadWrapper = styled.div`
  margin-bottom: 29.43px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 60px;
  }
`;

export const GenericTitleWrapper = styled.div`
  margin-bottom: 30px;
  overflow-y: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 60px;
    max-width: 70%;
  }
`;

export const GenericHeadBodyWrapper = styled.div`
  margin-bottom: 29px;
  width: 100%;
  max-width: 502px;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-bottom: 90px;
  }
`;

export const GenericTitle = styled(Typography)``;

export const GenericHeadBody = styled(Typography)``;

export const MediaAssetWrapper = styled.div`
  width: 100%;
  position: relative;

  /* height: 300px;
  position: relative;
  overflow: hidden; */

  /* img {
    width: 100%;
    height: auto;
  } */
`;

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: 1;
`;

export const StyledImage = styled.img`
  height: 70%;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;
